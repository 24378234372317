import { ApiBaseFront } from '../api-base-front';

export type GetResponseCloudFolderToken = {
  token: string,
};

type Params = {
  kijshopCd: string,
  orderId: string,
  path: string,
};

export class ApiGetCloudFolderDownloadToken extends ApiBaseFront<GetResponseCloudFolderToken> {
  constructor(_param: Params) {
    const { kijshopCd, orderId, ...param } = _param;
    super(
      'POST',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/token/download`,
      'JSON',
      param,
    );
  }
}
