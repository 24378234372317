import { CustomError } from '../../../models/custom-error';
import { ApiGetCloudFolderDownloadToken } from '../../front/cloud-folder/api-get-cloud-folder-file-download-token';
import { ApiBaseCloudFolder, ApiBaseCloudFolderImageGetOne } from '../api-base-cloud-folder';

type Params = {
  kijshopCd: string,
  orderId: string,
  path: string,
};

export class ApiGetCloudFolderFileDownload extends ApiBaseCloudFolderImageGetOne {
  private tokenParam: Params = {
    kijshopCd: '',
    orderId: '',
    path: '',
  }

  private token: string = '';
  constructor(_param: Params) {
    super(
      'GET',
      `/api/v1/c-folder/shop/${_param.kijshopCd}/file/download`,
      'BLOB',
      {},
    );
    this.tokenParam = _param;
  }

  async do() {
    const tokenApi = new ApiGetCloudFolderDownloadToken(this.tokenParam);
    const tokenResponse = await tokenApi.do();
    if (!(tokenResponse instanceof CustomError)) {
      this.token = tokenResponse.body.data?.token ?? '';
      this.param = { token: this.token };
    }
    return super.do();
  }
}
